<template>
    <div>
        <v-overlay absolute class="langs_overlay messiri_fonted" :value="langsOverlay" color="#f3f3f3" opacity="0.9" :key="index">

            <span @click="langsOverlay = false" style="content: url('/assets/img/close.svg'); width:25px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
            <span class="footer_desc">{{$t('close')}}</span>

            <v-img src="/assets/img/footer_logo2.svg" height="65" class="mx-auto" width="319"></v-img>

            <div class="footer_desc mt-3 mb-14 text-center"> {{$t('alkafeel_title')}}  - {{ $t('alkafeel_desc') }}</div>

            <div class="messiri_fonted text-center" mb-6>
                <template v-for="lang in langs" >
                    <template v-if="lang['display'] == 1">
                        <span v-if="lang['dir'] == 1" class="lang_title" :key="lang" dir="rtl">
                            <span style="content: url('/assets/img/footer_title_ico.svg'); vertical-align: bottom;"></span>
                            <a style="color: #5B5B5B;text-decoration: none; margin:0 10px;font-size: 32px;" :href="'?lang='+lang['code']">{{lang['name']}} </a>
                        </span>
                    </template>
                </template>
            </div>

            <v-layout row class="messiri_fonted text-center" mt-6 mb-6 align-center style="padding-right: 22px; text-align:center">
                <template v-for="lang in langs" >
                    <template v-if="lang['display'] == 1">
                        <v-flex v-if="lang['dir'] == 2" class="lang_title" :key="lang" style="direction:rtl">
                            <a style="color: #5B5B5B;text-decoration: none; margin:0 10px;font-size: 32px;" :href="'?lang='+lang['code']">{{lang['name']}} </a>
                            <span style="content: url('/assets/img/footer_title_ico.svg'); vertical-align: bottom;"></span>
                        </v-flex>
                    </template>
                </template>
            </v-layout>

            <hr style="color:#C4C4C4">


        </v-overlay>



        <div class="service_bar" :class="{hide: drawer}" style="left:20px; background-color: rgba(0,0,0,.4);">



            <router-link target="_blank" :to="'/stream?lang='+lang" style="text-decoration: none;">
                <div class="service_ico" style="margin-top:7px" >
                    <div class="news_serv">
                        <svg viewBox="0 0 23 25"><use xlink:href="/assets/img/services/live.svg#view"></use></svg>
                    </div>
                    <div class="service_ico_def tl">{{ $t('index_live') }}</div> 
                </div>
            </router-link>

            <div class="service_ico hidden-sm-and-down" @click="targrtLink('https://alkafeel.net/radio/?lang='+lang)">
                <div class="news_serv">
                    <svg viewBox="-3 0 26 26"><use xlink:href="/assets/img/services/podcast2.svg#view"></use></svg>
                </div>
                <div class="service_ico_def tl">{{ $t('radio') }}</div>
            </div>

            <v-menu v-model="prayerMenu" :close-on-content-click="false" :nudge-width="200" offset-x >
                <template v-slot:activator="{ on, attrs }">
                    <div class="service_ico" v-bind="attrs" v-on="on" >
                        <div class="news_serv">
                            <svg viewBox="0 0 25 30"><use xlink:href="/assets/img/services/times.svg#view"></use></svg>
                        </div>
                        <div class="service_ico_def tl">{{ $t('prayer_times') }}</div>
                    </div>
                </template>

                <v-card dark>
                    <v-list light>
                        <v-list-item>  
                            <v-list-item-title> {{ $t('index_prayer') }} </v-list-item-title> 
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list dense>
                        <v-list-item v-for="text in azan_text" :key="text" class="messiri_fonted">
                            <v-flex class="prayer_text pa-0" md6>{{text[0]}}:</v-flex>  
                            <v-flex class="prayer_text pa-0" md6 text-left>{{prayer[text[1]]}}</v-flex>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>

            <router-link target="_blank" :to="'/contact?lang='+lang" style="text-decoration: none;">
                <div class="service_ico" >
                    <div class="news_serv">
                        <svg viewBox="0 0 25 30"><use xlink:href="/assets/img/services/contact.svg#view"></use></svg>
                    </div>
                    <div class="service_ico_def tl">{{ $t('index_callus') }}</div>
                </div>
            </router-link> 
            <a :href="'/muharram/?lang='+lang+'#visitor'"  target="_blank" style="text-decoration: none;">
                <div class="service_ico" >
                    <div class="news_serv">
                        <svg viewBox="0 0 25 30"><use xlink:href="/assets/img/services/location.svg#view"></use></svg>
                    </div>
                    <div class="service_ico_def tl">{{ $t('visitor_guide') }}</div>
                </div>
            </a>



            <div class="service_ico" style="margin-top:7px" @click="langsOverlay = !langsOverlay">
                <div class="news_serv">
                    <svg viewBox="0 0 25 30"><use xlink:href="/assets/img/services/lang.svg#view"></use></svg>
                </div>
                <div class="service_ico_def tl">{{ $t('langs') }}</div>
            </div>


            <v-btn width="22" class="hidden-md-and-up mx-4 togg_btn" icon @click="toggle_dark_mode()" > 
                <svg v-if="$vuetify.theme.dark" width="22px" viewBox="0 0 15 15" fill="#FFF"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                <svg v-else viewBox="0 0 15 15" width="22px" fill="#FFF"><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
            </v-btn>
            <div class="service_ico_def tl hidden-md-and-up">{{$t('dark_mode')}}</div>

        </div>



        



    </div>
</template>

<script>
export default {
    data() {
        return {
            // search data
            drawer: false,
            langsOverlay: false,
            langs: [],
            lang: 'ar',

            dir : 'rtl',
            dateD: '',
            dateH: '',
            dateH_ico: '',
            dateM: '',

            prayerMenu: false,
            azan_text: [[this.$t("index_fajir"), 'fajer'], [this.$t("index_sunrise"), 'rise'], [this.$t("index_doher"), 'noon'], [this.$t("index_maghrib"), 'ghrob'], [this.$t("midnight"), 'mid']],
            prayer: {"fajer": "00:00","rise": "00:00","noon": "00:00","ghrob": "00:00","mid": "00:00"},

        }
         
    },
    methods: {
        targrtLink(link) {
            return window.open(link,'_blank')
        },
    },
    created () {
        this.$axios.get('https://alkafeel.net/main_bg/data/core_langs_main.json')
        .then(d => {
            this.langs = d.data; 
        })

        this.$axios.get('salaDate')
        .then(d => {
            if (typeof(d.data[0]) !== 'undefined') {this.prayer = d.data[0]}
        })
        if (this.$route.query.lang) {
            this.lang = this.$route.query.lang;
        }
    },
    
}
</script>


<style scoped>
    .related_overlay >>> .v-overlay__content,  .search_overlay >>> .v-overlay__content,  .langs_overlay >>> .v-overlay__content{
        width: 80%;
    }
    .paging_item {
        background: #1e1e1e;
        color: #fff;
        appearance: button;
        border-radius: 4px;
        font-size: 1rem;
        height: 34px;
        margin: .3rem;
        min-width: 34px;
        padding: 5px;
        text-decoration: none;
        transition: .3s cubic-bezier(0,0,.2,1);
        width: auto;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
        cursor: pointer;
    }
    .paging_selected {
        background-color: #2196f3 !important;
    }
    .search_overlay_result {
        position: absolute!important;
        bottom: unset!important;
        padding-bottom: 300px;
    }
    .drawer {
        position: absolute;
        top:0!important;
        right:0;
        left:0;
        width:100%!important;
        bottom: 0;
    }
    .search_more {
        padding-top:5%
    }
    .overlay_sub_title {
        font-weight: bold;
        color: #5B5B5B;
    }
    .overlay_sub_ico {
        height: 32px;
        display: ruby-text-container;
        margin: 0 5px; 
    }
    .overlay_title {
        color: #5B5B5B; 
        vertical-align: top; 
        line-height: 40px; 
        font-size:30px; 
        width:100%
    }

    .service_bar {
        position: fixed;
        top: 250px;
        background: rgba(0, 0, 0, 0);
        z-index: 2;
    }
    @media (max-width: 1264px) {
        .service_bar {
            top: 90px;
            position: absolute
        }
    }
    .service_ico {
        width: 60px;
        text-align: center;
        cursor: pointer;
        padding-bottom: 8px;
        z-index: 5;
    }
    .service_ico img {
        max-width: 50px;
    }

    .news_serv {
        fill:#FFF;
        width: 25px;
        height: 25px;
        margin: 0 auto 5px;
    }
    .service_ico:hover .news_serv{
        fill: #B1BD52!important;
    }

    .footer_desc {
        color: #5B5B5B;
        font-size: 14px;
    }
    .service_ico_def {
        color: #FFF;
        font-size: 10px;
    }
    .service_ico:hover .service_ico_def{
        color: #B1BD52 !important;
    }
    .overlay_sub_title {
        font-weight: bold;
        color: #5B5B5B;
    }
</style>