<template>
    <div class="video-container " :dir="dir">
        <img id="poster" class="poster-img zoom-in-out" ref="poster" :src="posterSource" alt="Poster">
        <div class="video-overlay" >
        <div class="imgs">
            <div class="logo hidden-sm-and-down">
            <img :src="logo" alt="">
            </div>
            <div class="mes">
                <p>{{ title }}</p>
            </div>
        </div>

        <template v-if="account">
            <div class="uinfo" style="text-align: center;">
                <!-- <img :src="img" style="border-radius: 50%; width: 60px; height: 60px; flex:1"> -->
                <!-- <div style="flex:1">{{ name }}</div> -->
                <p> {{ $t('photos_welcome') }} <span>{{ name }}</span></p>
                <div class="options">
                    <div :title="$t('account_logout')" @click="logout()" class="logout icon"></div>
                    <div v-if="last[0]" :title="$t('msg_history')" class="vhistory icon" @click="modal.show()"></div>
                </div>
            </div>

            
        </template>







        <p class="para">{{$t('msg_message_to_shrine_text_index')}}</p>
        <v-form class="custom_form" ref="form" v-model="valid">

            <!-- <label>{{ $t('name') }}</label> -->
            <v-text-field class="mt-1 pt-0" :disabled="account" :placeholder="$t('name')"  type="text" background-color="rgba(255, 255, 255, 1)" v-model="name" :rules="[rules.required]" id="custom-name" required></v-text-field>


            <v-text-field  class="mt-1 pt-0 custom-email" :disabled="account" type="email"  :placeholder="$t('msg_email_optional')" background-color="rgba(255, 255, 255, 1)" v-model="email" :rules="[rules.email]" id="email"></v-text-field>

            <!-- <label>{{ $t('contact_subject') }}</label> -->
            <v-text-field  class="mt-1 pt-0 custom-subject" type="text" :placeholder="$t('contact_subject')"  background-color="rgba(255, 255, 255, 1)" v-model="subject" :rules="[rules.required]" id="subject" required></v-text-field>

            <!-- <label>{{ $t('msg_contents') }}</label> -->
            <v-textarea  class="mt-1 pt-0" :placeholder="$t('msg_contents')" background-color="rgba(255, 255, 255, 1)" v-model="message" :rules="[rules.required]" id="custom-message" required></v-textarea>

            <div class="form-group d-flex">
                <div class="cu-btn " :loading="loading" @click="message_send()">{{ $t('msg_create') }}</div>
            </div>
        </v-form>
        

        

        <template v-if="!account">
            <div class="line"></div>

            <h4 class="mt-4" style="text-align: center; color:#000">{{ $t('msg_have_account') }}</h4>
            <a :href="'https://alkafeel.net/Account/?lang='+socails.code+'&redirect=https://alkafeel.net/message?lang='+socails.code" class="cu-btn mt-3">{{ $t('account_login') }}</a>
        </template>
            <div class="line"></div>
            <div class="search-name" >
                <h4 style="text-align: center; color:#000"> {{ $t('msg_follow_code') }} </h4>
                <img src="/assets/img/message/otp.svg" style=" height: 72px;" alt="">
            </div>
            <form class="otp-form">
                <div class="form-group">
                <label for="otp"> {{ $t('msg_enter_code') }}:</label>

                    <v-otp-input background-color="#FFF" id="custom-otp" :length="8" v-model="otp" style="direction: ltr;" ></v-otp-input>
                
                </div>

                <div class="form-group">
                <div class="cu-btn" @click="message_stat()">{{ $t('action_send') }}</div>
                </div>
            </form>
        <div class="line"></div>

        <div class="social">
            <a :href="ytUrl" target="_blank">
            <img src="/assets/img/message/youtube.svg" alt="">
            </a>
            <a :href="fbUrl" target="_blank">
            <img src="/assets/img/message/facebook.svg" alt="">
            </a>
            <a :href="isUrl" target="_blank">
            <img src="/assets/img/message/instagram.svg" alt="">
            </a>
            <a :href="twUrl" target="_blank">
            <img src="/assets/img/message/twitter.svg" alt="">
            </a>

        </div>
        </div>
        
            <video id="myVideo" ref="myVideo" loop preload="metadata">
                <source :src="videoSource" type="video/mp4">
            </video>
            
        
        <button class="btn2" @click="toggleVideo()"><img :src="ico"></button>


        <template >
          <div class="modal"  ref="history_modal" style="z-index: 99;">
            <div class="modal-dialog">
                <div class="modal-content">

                    <div class="modal-header" style="display: flex;">
                        <h4 class="modal-title" style="flex: 1;"> {{$t('msg_latest_stat')}} </h4>
                        <button @click="modal.hide()"><img src="/assets/img/message/close.png" width="35px"></button>
                    </div>

                    <div class="modal-body" style="position: relative">

                            <div class="mCSB_container">
                                
                                <div class="item" :class="expandedIndex === i ? 'collapse' : 'expand'"  v-for="(item, i) in last" :key="i">
                                    <div class="blur"></div>
                                    

                                    <div class="finfo">
                                      <div class="icon" @click="expand(i)"></div>
                                      <div class="fdetails ta dir">
                                          <div class="code">
                                              <div> {{$t('msg_message_code')}} : </div>
                                              <div> {{ item.code  }}</div>
                                          </div>
                                          
                                          <div class="title">
                                              <div> {{$t('message_subject')}} : </div>
                                              <div>{{ item.subject  }}</div>
                                          </div>
                                          <div class="senddate">
                                              <div> {{$t('msg_send_date')}} : </div>
                                              <div>
                                                  {{ item.ts.substring(0,10) }}
                                              </div>
                                          </div>
                                          <div class="status">
                                              <div> {{$t('msg_message_status')}} : </div>
                                              <div v-if="item.printed === 'yes'">
                                                {{ $t('msg_message_approved') + ' ' + $t('msg_printed') }}
                                              </div>
                                              <div v-else-if="item.status === 'approved'">
                                                {{ $t('msg_message_approved')  }}
                                              </div>
                                              <div v-else-if="item.status === 'rejected'">
                                                {{ $t('msg_message_rejected') + ' ' + $t('msg_bad_content') }}
                                              </div>
                                              <div v-else>
                                                {{ $t('msg_recieved') +' '+  $t('msg_waiting_for_printing')}}
                                              </div>
                                          </div>
                                          <div class="reactdate" v-if="item.review_date">
                                              <div> {{$t('msg_review_date')}} : </div>
                                              <div> {{ item.review_date.substring(0,10) }}</div>
                                          </div>
                                          
                                      </div>
                                    </div>

                                    <div class="summary" :style="expandedIndex === i ? 'display: none;' : ''">
                                        
                                        <div class="icon " :class="item.status">
                                            <div class="clock">
                                                <div class="hour"></div>
                                                <div class="minute"></div>
                                            </div>
                                        </div>
                                        <div class="title">{{ item.subject }}</div>
                                        <div class="date">
                                            {{ item.ts.substring(0,10) }}
                                        </div>
                                    </div>
                                </div>




                                
                            </div>

                    </div>
                </div>
            </div>
        </div>
      </template>

        <service-bar></service-bar>
    </div>
</template>

<script>
import services from './searvice_bar.vue';
import Swal from "sweetalert2";
import cookies from 'vue-cookies'
import { Modal } from 'bootstrap'
export default {
  data() {
    return {
        title: this.$t('msg_message_to_shrine'),
        loading: false,
        logo: 'https://alkafeel.net/src/svg/logo5.svg',
        videoSource: 'https://alkafeel.net/message/assets/vid/shrine.mp4',
        posterSource: '/assets/img/message/bg.jpg',
        posterImages: [
            '/assets/img/message/bg.jpg',
            '/assets/img/message/bg1.jpg',
            '/assets/img/message/bg2.jpg'
        ],
        //ico: '&#9658;',
        ico: '/assets/img/message/play.svg',
        loader: null,
        name: '',
        email: '',
        subject: '',
        message: '',
        valid: false,
        otp: null,
        wait: 10, // in minutes
        rules: {
            required: value => !!value || this.$t("required_field"),
            min: (v) => v.length >= 3 || this.$t("three_letters"),
            email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("alert_wrong_email"),
        },
        lang: 'ar',
        dir : 'rtl',
        langs: [],

        fbUrl: 'https://facebook.com/alkafeel.global',
        isUrl: 'https://instagram.com/alkafeel.global.network/',
        ytUrl: 'https://youtube.com/alkafeelnet',
        twUrl: 'https://twitter.com/AlkafeelAbbas',
        socails: null,

        token: null,
        img: null,
        account: false,
        last: null,

        modal: null,
        expandedIndex: null,

    }
  },
    components: {
        'service-bar': services
    },
  watch: {
        loader() {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 3000)
            this.loader = null
        },
    },
  created() {
        document.title =this.title; 
        this.ini();
    },
    updated() {
      this.$vuetify.theme.dark = true;
    },
  methods: {
    ini () {
        this.socails = localStorage.getItem('vuex') ? JSON.parse(localStorage.getItem('vuex')).social : '';
        if (this.socails !== ''){
            this.fbUrl = this.socails.fb;
            this.isUrl = this.socails.inst;
            this.ytUrl = this.socails.yt;
            this.twUrl = this.socails.twtr;
            if (this.socails.dir === '2')  { 
                this.dir = 'ltr';
            }
            if (this.socails.logo !== 'logo6.svg')  {
                this.logo = 'https://alkafeel.net/uploads/' + this.socails.logo;
            } else {
                this.logo = 'https://alkafeel.net/src/svg/logo5.svg';
            }
        }
        if (cookies.get('kgUserid')) {
            this.token = cookies.get('kgUserid');
            this.$axios.post('/alkafeelMessage/user', {'token' : this.token})
            .then((response)=>{
                if (response.data.name !== null) {
                    this.name = response.data.name.title
                    this.email = response.data.name.email
                    this.account = true;
                    this.last = response.data.code
                    if (response.data.name.img) {
                        this.img = 'https://alkafeel.net/Account/upload/avatar/'+response.data.name.img
                    } else {
                        this.img = '/assets/img/message/user-2.svg';
                    }
                }
            })
        }
        
    },
    expand(index) {
      if (this.expandedIndex !== null) {
        this.expandedIndex = null;
      } else {
        this.expandedIndex = index;
      }
      
    },
    logout() {
        cookies.remove("kgUserid", null, 'alkafeel.net');
        cookies.remove("kgUserid", null, 'pic.alkafeel.net');
        // cookies.remove("kgUserid", null, 'localhost');
        this.$router.go();
      },
    setRandomPoster() {
      const randomIndex = Math.floor(Math.random() * this.posterImages.length);
      this.posterSource = this.posterImages[randomIndex];
    },
    toggleVideo() {
      if (this.$refs.myVideo.paused) {
        this.$refs.myVideo.style.opacity = '1';
        this.$refs.poster.style.opacity = '0';
        setTimeout(() => {
          this.$refs.myVideo.play();
        }, 1000);
        // this.$refs.btn.innerHTML = "&#10074;&#10074;";
        // this.ico = "&#10074;&#10074;";
        this.ico = '/assets/img/message/pause.svg'
        setTimeout(() => {
          this.$refs.poster.classList.remove('zoom-in-out');
          this.$refs.poster.style.display = 'none';
        }, 1000);
      } else {
        this.$refs.myVideo.pause();
        this.$refs.myVideo.style.opacity = '0';
        this.$refs.poster.style.display = 'block';
        this.$refs.poster.classList.add('zoom-in-out');
        this.$refs.poster.style.opacity = '1';
        // this.$refs.btn.innerHTML = "&#9658;";
        // this.ico = "&#9658;"
        this.ico = '/assets/img/message/play.svg'
        this.setRandomPoster();
      }
    },
    checkCookie(cname, id) {
        let username = this.getCookie(cname);
        if (username != "") {
            if (id === username) {
                return true
            } else {
                return false
            }
        }
    },
    setCookie(cname, cvalue) {
        const d = new Date();
        d.setTime(d.getTime() + (this.wait*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    message_stat() {
        let query = { code: this.otp }
        this.$axios.post('alkafeelMessage/stat', query)
        .then((res) => {
            this.otp = null
            if (res.data === 1) {
                Swal.fire({
                    title: this.$t("msg_message_approved"),
                    text: "",
                    icon: "success",
                    timer:5000,
                    confirmButtonColor: '#B1BD52',
                });
            } else if (res.data === 2) {
                Swal.fire({
                    title: this.$t("msg_message_approved") +' '+ this.$t("msg_printed"),
                    text: "",
                    icon: "success",
                    timer: 5000,
                    confirmButtonColor: '#B1BD52',
                });
            } else if (res.data === 3) {
              Swal.fire({
                  title: this.$t("msg_message_rejected") +' '+ this.$t("msg_bad_content"),
                  text: "",
                  icon: "error",
                  timer: 5000,
                  confirmButtonColor: '#B1BD52',
              });
            } else if (res.data === 4) {
              Swal.fire({
                  title: this.$t("msg_check_your_code"),
                  text: "",
                  icon: "error",
                  timer: 5000,
                  confirmButtonColor: '#B1BD52',
              });
            } else {
                Swal.fire({
                    title: this.$t("msg_recieved") +' '+ this.$t("msg_waiting_for_printing"),
                    text: "",
                    icon: "info",
                    timer: 3000,
                });
            }
        })

    },
    message_send() {
        if (this.$refs.form.validate()) {
            this.loader = 'loading';
            var query;
            query = {
                name: this.name,
                email: this.email,
                token: this.token,
                subject: this.subject,
                message: this.message,
            }

            if (this.checkCookie('message', 'DMI')) {
                Swal.fire({
                    title: this.$t("messege_wait")+' '+this.wait+' '+this.$t("time_mins"),
                    text: "",
                    icon: "warning",
                    // timer: 3000,
                    confirmButtonColor: 'red',
                });
            } else {

                
                this.$axios.post('alkafeelMessage/store', query).then((res) => {
                    if (res.status == 200) {
                        Swal.fire({
                            
                            title:  '<strong>'+this.$t('msg_message_sent')+'</strong>',
                            html: this.$t('msg_use_code_to') +  '  &nbsp <h2>' + res.data.code+'</h2>',
                            icon: "success",
                            confirmButtonColor: '#B1BD52', 
                            confirmButtonText: this.$t('msg_copy_code'),
                            preConfirm: () => {
                           // Function to copy the code to clipboard
                              const el = document.createElement('textarea');
                              el.value = res.data.code;
                              document.body.appendChild(el);
                              el.select();
                              document.execCommand('copy');
                              document.body.removeChild(el);
                            }
                        });
                        if (!this.account) {
                          this.name = '';
                          this.email = '';
                        }
                        this.subject = '';
                        this.message = '';

                        this.setCookie('message', 'DMI');
                    }
                    else {
                        Swal.fire({
                            title: this.$t("alert_send_error"),
                            text: "",
                            icon: "error",
                            timer: 3000,
                            confirmButtonColor: 'red',
                        });
                    }
                }
                ).catch(() => {
                    Swal.fire({
                        title: this.$t("alert_send_error"),
                        text: "",
                        icon: "error",
                        timer: 3000,
                        confirmButtonColor: 'red',
                    });
                });
            }


        }
    },
  },
  mounted() {
    this.setRandomPoster();
    this.$refs.myVideo.addEventListener('ended', () => {
      this.setRandomPoster();
      this.$refs.myVideo.style.opacity = '0';
      this.$refs.poster.style.display = 'block';
      this.$refs.poster.classList.add('zoom-in-out');
      this.$refs.poster.style.opacity = '1';
    });

    this.modal = new Modal(this.$refs.history_modal)
  }
}
</script>
<style>
@media  screen and (min-width: 769px){
  .custom_message_wrapper {
    display: none!important;
  }
  .video-container {
    margin-top: -25px;
  }
}
.custom_message .d-toggle {
  display: none;
}
#custom-otp input {
  color: #000!important;
}
#email, #custom-name, #subject, #custom-message {
    color: #000!important;
}
#email::placeholder, #custom-name::placeholder, #subject::placeholder, #custom-message::placeholder {
  color: rgba(0, 0, 0, .77)!important;
}
.theme--dark.v-input--is-disabled #email, .theme--dark.v-input--is-disabled #custom-name {
    color: rgba(0, 0, 0, 0.5)!important;
}
#custom-otp--0, #custom-otp--1, #custom-otp--1, #custom-otp--2, #custom-otp--3, #custom-otp--4, #custom-otp--5, #custom-otp--6, #custom-otp--7 {
  color: #000!important;
}
</style>
<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Almarai&display=swap');
* {
    font-family: 'Almarai' !important;
} */
.modal-content {
    max-width: 900px!important;
}


.summary {
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  margin-top: -70px;
}
.modal-body .items {
  width: 100%;
  height: 90%;
  max-height: 90vh;
  padding: 10px;
  border: 1px solid gray;
  margin: 10px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.modal-body .item.expand {
  border-radius: 50%;
  max-width: 250px;
  max-height: 250px;
  padding: 0;
  position: relative;
}
.mCSB_container {
  display: flex;
  justify-content: space-around;
  align-content: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  min-height: 200px;
}
.summary .icon {
  width: 100px;
  height: 100px;
  opacity: .8;
}
.summary > div {
  display: block;
  text-align: center;
  margin: 0 auto;
}
.summary .approved {
  background-position: -556px -400px;
}
.item .summary .rejected {
  background-position: -556px -505px;
}
.pending .clock {
  border: 5px solid #dc8304;
  border-radius: 100%;
  display: block;
  margin: 10px auto 20px auto;
  position: relative;
  width: 70px;
  height: 70px;
}
.pending .clock .hour {
  background: #dc8304;
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 0;
  transform-origin: 50% 100%;
  border-radius: 2px;
  animation: spin 7s linear infinite;
  margin: -20px -2px 0;
  padding: 20px 2px 0;
}
.pending .clock .minute {
  background: #dc8304;
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 0;
  transform-origin: 50% 100%;
  border-radius: 2px;
  animation: spin 1s linear infinite;
  margin: -30px -2px 0;
  padding: 30px 2px 0;
}
@keyframes spin {100% {transform:rotate(360deg);}}





.item.expand .finfo {
  opacity: 0;
  margin-top: 20%;
  display: flex;
  text-align: center;
  transition: ease-in-out all .5s;
}
.item.collapse .finfo {
  opacity: 1;
  margin-top: 0;
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  left:0;
  z-index: 999;
  overflow: auto;
  background: #000000;
}
.item .finfo {
  text-align: center;
  z-index: 999;
}
.finfo .icon {
  width: 70px;
  height: 70px;
  display: flex;
  margin: -7px auto 0 auto;
  cursor: pointer;
  border-radius: 50%;
  opacity: .6;
  text-align: center;
  z-index: 99;
}
.finfo .icon:hover {
  opacity: 1;
  cursor: pointer;
}
.item.expand .finfo .icon {
  background-position: -418px -226px;
  transition: ease-in-out all .1s;
}
.item.collapse .finfo .icon {
  background-position: -418px -301px;
}
.item .finfo .stext {
  padding: 7px 15px 15px 15px;
}
.item.expand .finfo .stext {
  display: block;
}
.item.collapse .finfo .stext {
  display: none;
}
.item.expand .finfo .fdetails {
  display: none;
}
.item.collapse .finfo .fdetails {
  display: inline-grid;
}
.item.collapse .finfo {
  min-height: 400px;
}
.item .finfo .fdetails > div {
  display: block;
  position: relative;
  padding: 15px 0 10px 0;
  opacity: .8;
}
.item .finfo .fdetails > div:hover {
  opacity: 1;
}
.item .finfo .fdetails > div:not(:last-child):after {
  content: '';
	position: absolute;
	bottom: 0px;
  right: 0;
	width: 80%;
	border-bottom: 1px solid #FFF;
}
.item .finfo .fdetails > div > div {
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
}

.item .finfo .fdetails > div > div:first-child {
  color: #ff2f2f;
  padding-left: 15px;
}
.items .no_result {
  display: block;
}
.items .no_result .text {
  color: #fff;
  font-size: 30px;
}
.item > .blur {
  background-color: rgba(0, 0, 0, 1);
  opacity: .1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1 !important;
  filter: blur(10px);
  border: 1px solid red;
}
.item:hover .blur {
  opacity: .3;
}
.item.expand:hover .summary {
  opacity: .1;
  transition: ease-in all .1s;
}
.item.expand:hover .finfo {
  opacity: 1;
  transition: ease-in all .3s;
}






.summary .pending {
  background-image: none !important;
}
.fdetails  .title {
  font-size: 16px;
}
.summary .title {
  font-size: 20px;
  width: 80%;
  line-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.summary .date {
  color: gray;
  font-size: 18px;
  opacity: .7;
  align-self: flex-end;
  width: 100%;
  margin-bottom: 15px;
}
.menu-icon {
  border: 2px solid #ae1f1f;
  height: 45px;
  width: 45px;
  cursor: pointer;
  left: 50%;
  position: absolute;
  margin: -20px 0 0 -20px;
  top: 50%;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.uinfo .options {
  min-height: 80px;
  min-width: 120px;
}

.uinfo .logout, .uinfo .vhistory {
  display: inline-block;
  vertical-align: top;
  text-align: right;
}
.uinfo .icon {
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 3px;
}
.icon {
  background-image: url('/assets/img/message/spritesV3.png');
}
.uinfo .logout {
  background-position: -422px -174px;
}
.uinfo .vhistory {
  background-position: -373px -174px;
}
.uinfo {
    margin-top: 30px;
    display: block;
}
.uinfo > p {
  color: #472f2f;
  font-size: 25px;
  line-height: 180%;
  height: 45px;
  padding: 0 10px 0 10px;
  margin: 0 10px 0 10px;
}
.uinfo > p span {
  color: #ae1f1f;
  font-weight: bold;
}
.mes p {
    text-align: center;
    color:#FFF;
    font-size: 30px;
    padding-top: 55px;
    text-shadow: 2px 2px 5px black;
}
.video-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 1s ease;
}

.poster-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transition: transform 20s ease, opacity 1s ease;
}

.poster-img.zoom-in-out {
    animation: zoomInOut 20s ease infinite;
}

@keyframes zoomInOut {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }
}

.btn2 {
    z-index: 2;
    background-color: #5a642263;
    color: white;
    border: 2px solid #d0dd793b;
    cursor: pointer;
    border-radius: 100%;
    margin-bottom: 16px;
    position: absolute;
    left: 34%;
    top: 45%;
    height: 100px;
    width: 100px;
    font-size: 38px;
}
.btn2 img {
  padding-top: 15px;
}

.btn2:hover {
    background-color: #59631fb0;
}

.fs22 {
    margin: 8px 4px;
}

.video-overlay {
    position: absolute;
    top: 0;
    right: 0%;
    transform: translateX(-50%);
    width: 400px;
    height: 100vh;
    z-index: 3;
    background: rgba(225, 225, 225, 0.77);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

}

.mes {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    width: 324px;
    height: 148px;
    /* display: block; */
    margin: 0 auto;
    text-align: center;
    background-image: url('/assets/img/message/title.png');
}

.imgs {
    margin-top: 30px;
}

.para {
    margin: 12px;
    font-size: 16px;
    text-align: center;
    color: #4b4b4b;
    line-height: 1.4;
    font-weight: bold;
}

.custom-form {
    width: 320px;
    margin: 0 auto;
    text-align: right;
}

.custom_form {
    width: 320px;
}

.custom-form label {
    font-weight: bold;
}

.custom-form .form-group {
    margin-bottom: 15px;
}

.custom-form .form-control {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin-top: 8px;
}

.custom-form .form-control:focus-visible {
    border: none !important;
}

.cu-btn {
    display: inline-block;
    padding: 6px 12px;
    width: 150px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #303030;
    color: #ffffff;
}

.form-group label {
    color: #464646;
}

.d-flex {
    display: flex;
    justify-content: center;
}

.line {
    width: 320px;
    height: 2px;
    background: #b0b0b0;
    margin: 12px 0;
}

.otp-form {
    width: 320px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.otp-form label {
    font-weight: bold;
}

.otp-form .otp-input {
    display: flex;
    margin: 12px 0 16px 0;
}

.otp-form .otp-input input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 24px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    background-color: #f5f5f5;
}

.otp-form .otp-input input:focus {
    outline: none;
    background-color: #ffffff;
}

.otp-input {
    direction: ltr !important;
}

.search-name {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.social {
    display: flex;
    width: 320px;
    justify-content: space-evenly;
    margin-top: 12px;
}


::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}


.service {
    position: fixed;
    left: 0;
    top: 20vh;
    z-index: 7;
    background:#000000b8;
    padding: 8px;
    border-radius: 4px;
}

.flex-column {
    flex-direction: column;
    align-items: center;
}


.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #0000009d;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    left: -20px;
    border-radius: 4px;
    text-align: center;

  }
  
  .dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #0a0a0a;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {background-color: #3e8e41;}


@media (min-width: 320px) and (max-width: 767px) {
  
    .video-overlay {
        transform: translateX(0%) !important;
        width: 100% !important;
    }
    
    .service {
        display: none !important;
    }

    .btn2 {
        display: none !important;
    }
    
    
  }
  
  .modal {
    display: none; 
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    transition: all 0.3s ease;
  }

  .modal.show {
    display: block; 
    opacity: 1;
  }

  .modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 0;
    border-radius: 5px;
    width: 80%;
    max-width: 600px;
  }

  .modal-header {
    padding: 8px 16px;
    background-color: #8ca63c;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .close {
    color: white;
    float: left;
    font-size: 36px;
    cursor: pointer;
    margin-top: 4px;
  }

  .close:hover,
  .close:focus {
    color: #f44336;
    text-decoration: none;
  }

  .modal-body {
    padding: 2px 16px;
    display: flex;
    flex-wrap: wrap;
}

.modal a {
    margin: 10px 11px;
    padding: 10px;
    background-color: #f2f2f2;
    color: #333;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
    width: 91px;
    display: flex;
    justify-content: center;
}

  .modal a:hover {
    background-color: #e8e8e8;
  }

</style>